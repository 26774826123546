import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource'

Vue.use(VueRouter)
Vue.use(VueResource)

import 'bootstrap/dist/css/bootstrap.css'

import SpotRand from './components/spot-rand'

// Create and configure vue-router
var router = new VueRouter({
  routes: [
    { path: '/', component: SpotRand }
  ]
})

// Redirect to login page on 401: NOT AUTHORIZED response
Vue.http.interceptors.push(() => {
  return function (response) {
    if (response.status == 401) {
      router.push("/")
    }
  }
})

new Vue({
  router,
  render: h => h(SpotRand)

}).$mount('#app')
