<template>
  <div>
    <!-- TOKEN: {{ token }} -->
    <div v-if="!token">
      <button class="btn btn-primary" @click="spotifyLogin()">LOGIN</button>
    </div>
    <div v-else>
      <p>
        Number of tracks: <input type="number" v-model="numTracks"/><br/>
        Maximum number of tries: <input type="number" v-model="maxTries"/><br/>
        <input type="checkbox" v-model="limitGenre"/>Limit genre: <input type="text" size="32" :disabled="!limitGenre" v-model="genre"/><br>
        <input type="checkbox" v-model="useCustomKeywords"/>Custom keywords (comma-separated):<br>
        <span v-for="keyword in customKeywords" :key="keyword">
          {{ keyword }}
          <button class="btn btn-primary btn-sm" @click="customKeywords = customKeywords.filter((k) => { return k !== keyword })">X</button>
          <br>
        </span><br>
        <input type="text" size="32" :disabled="!useCustomKeywords" v-model="addCustomKeyword"/>
        <button class="btn btn-primary" :disabled="!useCustomKeywords" @click="customKeywords.push(addCustomKeyword); addCustomKeyword = ''">Add</button>
      </p>
      <p>
        Use dictionaries:<br>
        <span v-for="dict in this.availableDictionaries" :key="dict">
          <input type="checkbox" v-model="selectedDictionaries" :value="dict">{{ dict }}
        </span>
      </p>
      <p>
        Playlist name:<input type="text" size="40" v-model="playlistName"/><br>
        Playlist description:<input type="text" size="100" v-model="playlistDescription"/><br>
      </p>
      <p>
        <button class="btn btn-default" @click="newPlaylist(numTracks)">HIT ME</button>
      </p>
      <p>
        <ol>
          <li v-for='(trackInfo, index) in playlist' :key='index'>{{ trackInfo.track.artists[0].name }} - {{ trackInfo.track.name }} ({{ trackInfo.keywords[0] }})</li>
        </ol>
      </p>
      <p>
        <button class="btn btn-default" @click="savePlaylist()" :disabled="(playlist.length == 0)">SAVE</button>
      </p>
      <p>
        STATUS: {{ status }}
        <br/>
        ERROR: {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import sprintf from "sprintf"

import Vue from "vue"

let SPOTIFY_API = "https://api.spotify.com/v1"
let DEFAULT_REQUEST_DELAY = 100 // ms

export default {
  name: "spot-rand",

  components: {
  },

  data () {
    return {
      token: undefined,
      queryResult: undefined,
      error: undefined,
      status: undefined,
      working: false,
      numTracks: 5,
      maxTries: 1000,
      limitGenre: false,
      genre: '',
      useCustomKeywords: false,
      addCustomKeyword: '',
      customKeywords: [],
      playlistName: '',
      playlistDescription: '',
      playlist: [],
      availableDictionaries: [],
      selectedDictionaries: [],
    }
  },

  mounted: function () {
    this.fetchAvailableDictionaries();

    if (!this.token) {
      let m = this.$route.fullPath.match(/access_token=([a-zA-Z0-9_+-./]+=*)/)

      if (m && m[1]) {
        this.token = m[1]
      }
    }
  },

  computed: {
    requestHeaders: function () {
      return {
        "headers": {
          "Authorization" : "Bearer " + this.token,
          "Content-Type" : "application/json"
        }
      }
    }
  },

  methods: {
    spotifyLogin: function () {
      let redirectUrl = encodeURI("https://spot-rand.wouterbijlsma.nl")

      window.location =
        "https://accounts.spotify.com/authorize?" +
        "client_id=ce6b38fadf9f43088beb1d40aa9bdfb3&" +
        "response_type=token&" +
        "show_dialog=true&" +
        "scope=playlist-modify-public&" +
        "redirect_uri=" + redirectUrl
    },

    playlistSelected: function (playlist) {
      this.selectedPlaylist = playlist
    },

    // Spotify query tracks with passed keyword and optional offset within search results and
    // limit (number of tracks)
    query: function (keyword, genre, offset=1, limit=1) {
      let q = genre ? `"*${keyword}*" genre:"${genre}"` : keyword
      let query = `${SPOTIFY_API}/search?q=${encodeURI(q)}&type=track&offset=${offset}&limit=${limit}`

      console.log(query)

      return Vue.http.get(query, this.requestHeaders)
    },

    // Clear current playlist and create new one with numTracks random tracks
    newPlaylist: function (numTracks) {

      this.playlist = []

      // Get words, either random words from the word server, or from the user-supplied custom keywords if enabled.
      let getWords = async () => {
        if (this.useCustomKeywords) {
          return this.customKeywords
        } else {
          return (await this.randomWords()).map((wordInfo) => wordInfo.word)
        }
      }

      // Push the passed number of tracks to the current playlist.  The function calls itself recursively until the
      // the playlist has the requested number of tracks, or the maximum number or tries is reached.
      let pushTracks = async (n, tries) => {
        this.working = true

        if (n == 0) {
          this.status = "Done!"
          this.working = false
          return
        }

        if (tries >= this.maxTries) {
          this.status = "Maximum number of tries reached :-("
          this.working = false
          return
        }

        this.status = `Working: ${this.playlist.length} tracks, ${tries}/${this.maxTries} tries`

        let words = await getWords()
        let word = (words.length !== 0 ? words[Math.floor(Math.random()*words.length)] : '')
        let genre = this.limitGenre ? this.genre : undefined

        this.randomTrack(word, genre).then((track) => {
          // Request successfush, push to playlist and get next random track
          let trackInfo = {
            "keywords": [ word ],
            "track": track
          }

          if (trackInfo && trackInfo.track && trackInfo.track.artists) {
            this.playlist.push(trackInfo)
            pushTracks(n-1, tries+1)
          }
          else {
            pushTracks(n, tries+1)
          }

        }, (res) => {
          // Request failed, retry. In case the server returned HTTP status 429 'Too many requests',
          // use the Retry-After header plus one secodn to determine the wait time, otherwise the
          // default request delay
          var retryAfter = DEFAULT_REQUEST_DELAY

          if (res.status == 429) {
            retryAfter = (parseInt(res.headers["Retry-After"]) + 1) * 1000
            this.status = `Spotify rate limit hit, waiting ${retryAfter/1000.0} seconds`
          }

          setTimeout(() => {
            pushTracks(n, tries+1)
          }, retryAfter)
        })
      }

      // Kick off recursion for the passed number of tracks
      pushTracks(numTracks, 0)
    },

    // Get the list of available dictionaries from the word server
    fetchAvailableDictionaries: function () {
      Vue.http.get("https://spot-rand.wouterbijlsma.nl/api").then((res) => {
        console.log(res.data)
        console.log(res.data['dictionaries'])
        this.availableDictionaries = res.data.dictionaries
      })
    },

    // Return some random words from the word server
    randomWords: function () {
      let dictionaries = this.selectedDictionaries.join(',')

      return new Promise((resolve, reject) => {
        Vue.http.get(`https://spot-rand.wouterbijlsma.nl/api/words/?dictionaries=${encodeURI(dictionaries)}`).then((res) => {
          resolve(res.data)
        }, () => {
          reject()
        })
      })
    },

    // Get random track using passed keyword to query spotify
    randomTrack: function (keyword, genre) {
      return new Promise((resolve, reject) => {
        // First get number of hits for the passed keyword
        this.query(keyword, genre, 1, 1).then((res) => {
          // Pick random offset inside range
          let maxOffset = Math.min(9999, res.data.tracks.total)

          if (maxOffset > 0) {
            let offset = Math.floor(Math.random() * Math.floor(maxOffset))

            // Query track with generated offset
            this.query(keyword, genre, offset, 1).then((res) => {
              resolve(res.data.tracks.items[0])
            }, () => {
              reject(res)
            })
          } else {
            reject(res)
          }
        }, (res) => {
          this.error = res.statusText
        })
      })
    },

    // Save current playlist
    savePlaylist: function () {
      let now = new Date()

      if (this.playlistName === '') {
        this.playlistName = sprintf("spot-rand %4d-%02d-%02d %02d:%02d:%02d.%03d",
          now.getFullYear(), now.getMonth() + 1, now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds())
      }

      if (this.playlistDescription === '') {
        this.playlistDescription = "spot-rand random playlist"

        if (this.useCustomKeywords) {
          this.playlistDescription += `, keywords: '${this.customKeywords.join(",")}'`
        }

        if (this.limitGenre) {
          this.playlistDescription += `, genre: '${this.genre}'`
        }
      }

      Vue.http.get(`${SPOTIFY_API}/me`, this.requestHeaders).then((res) => {
        let userId = res.data["id"]

        let playlistData = {
          "name": this.playlistName,
          "description": this.playlistDescription
        }

        Vue.http.post(`${SPOTIFY_API}/users/${userId}/playlists`, playlistData, this.requestHeaders).then((res) => {
          let playlistId = res.data["id"]

          let trackData = {
            "uris": this.playlist.map(trackInfo => trackInfo.track.uri)
          }

          Vue.http.post(`${SPOTIFY_API}/playlists/${playlistId}/tracks`, trackData, this.requestHeaders).then(() => {
            this.status = 'SUCCESS!'
          }, (res) => {
            this.error = `failed to add tracks to playlist: ${res.statusText} (${res.data})`
          })
        }, (res) => {
          this.error = `failed to create playlist: ${res.statusText} (${res.data})`
        })
      })
    }
  }
}
</script>
